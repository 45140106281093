import React from "react";
import styles from "./Loader.module.sass";
import Loader from "../../components/Loader";
import cn from "classnames";

const Loading = () => {
  return (
    <div className={styles.full}>
      <div className={styles.foot}>
        <button className={cn("button-stroke ", styles.button)}>
          <Loader className={styles.loader} />
          <span>Cargando</span>
        </button>
      </div>
    </div>
  );
};

export default Loading;
