import { paxios } from "../../utils/axios";

export const getOne = async (id) => {
  const url = `/lista/${id}`;
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};
export const update = async (
  _id,
  tituloFicha,
  datasDrawsTotal,
  markersSave,
  polylinesSave
) => {
  const {
    marcadoresCount,
    mangasCount,
    reservasCount,
    nap_iCount,
    nap_iiCount,
    ontCount,
    postesCount,
    rutasCount,
    sumaDistanceRutas,
  } = datasDrawsTotal;

  try {
  console.log(_id,
      tituloFicha,
      datasDrawsTotal,
      markersSave,
      polylinesSave);
    // reset id so we can prevent duplicate markers(prevent multiple marker with same id)
    markersSave.forEach((marker, index) => {
      if(marker) {
          marker.id = index
      }
    });

    const { data } = await paxios.post(`/lista/update/${_id}`, {
      idFicha: _id,
      titulo: tituloFicha,
      markers: markersSave,
      polylines: polylinesSave,
      marcadores: marcadoresCount,
      rutas: rutasCount,
      mangas: mangasCount,
      reservas: reservasCount,
      sumatotalrutas: sumaDistanceRutas,
      nap_i: nap_iCount,
      nap_ii: nap_iiCount,
      ont: ontCount,
      postes: postesCount,
    });
    return data;
  } catch (e) {
    console.log("hello fromupdae", e);
    throw e;
  }
};
export const dashboard = async () => {
  const url = "/lista/dashboard";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};

export const role = async () => {
  const url = "/user/role";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};
export const user = async () => {
  const url = "/user/userinfo";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};

export const permissionSecundario = async () => {
  const url = "/user/permissionsecundario";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};
